<style>
.sold-products-table {
    border-radius: 0;
    .v-data-table-header {
        th {
            &:nth-child(6), &:nth-child(7) {
                text-align: center !important;
            }
        }
    }

    thead{
        tr{
            th{
                border-bottom: 1px solid black !important;
                text-transform: uppercase;
                &:nth-child(1){
                    padding-left: 12px !important;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                &:nth-child(1){
                    padding-left: 12px !important;
                }
                &:nth-child(6), &:nth-child(7) {
                    text-align: center !important;
                }
            }
        }
    }
}
.card-title{
    max-height: 50px;
    padding: 6px 12px;
    text-transform: uppercase;
    color: white;
}
.brand-div{
    border: 1px solid rgba(0, 0, 0, 0.08);
    transition: all 0.2s ease-in-out;
    padding: 6px;

    .brand-description{
        color: black;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
    }

    .brand-info{
        font-size: 13px;
        color: black;
        text-align: center;
    }

    &:hover{
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
}
.custom-date-picker{
    button{
        min-width: 55px !important;
    }
}
</style>
<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <page-title
                        :title="$t('sales')+' Cliente/Products'"
                ></page-title>
            </v-col>
            <v-col class="text-right">

            </v-col>
        </v-row>

        <!--  SEARCH AND SELECT THE CLIENT / DATAS SELECIONADAS -->
        <v-row justify="center">

            <!-- CONFIGURAR -->
            <v-col cols="12" md="6">
                <v-card min-height="475">
                    <v-card-title class="card-title" style="background-color: #00a0df">
                        Configurar
                    </v-card-title>
                    <v-card-text>
                        <!-- SEARCH AND SELECT CLIENT -->
                        <v-row>
                            <v-col cols="12">
                                <h4 class="pt-1 pb-1" style="color: black">Selecionar cliente:</h4>
                                <v-autocomplete
                                        v-model="selectedClient"
                                        :items="clients"
                                        append-icon="search"
                                        clearable
                                        hide-details
                                        item-text="Cliente"
                                        item-value="Cliente"
                                        label="Procura pelo código do cliente..."
                                        outlined
                                        @change="selectedClient != null ? getSoldProductsList() : ''"
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-title>
                                                Sem resultados...
                                            </v-list-item-title>
                                        </v-list-item>
                                    </template>
                                    <template v-slot:selection="{ attr, on, item, selected }">
                                        <span>{{ item.Cliente + ' - ' + item.Nome }}</span>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.Cliente"></v-list-item-title>
                                            <v-list-item-subtitle v-text="item.Nome"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>

                        <!-- SELECT DATE INTERVAL -->
                        <v-row>
                            <v-col cols="12" class="pa-0" >
                                <h4 class="pt-1 pb-1 pl-3" style="color: black">Selecionar o intervalo de datas:</h4>
                            </v-col>
                            <v-col cols="12" class="pa-0" style="display: flex;justify-content: space-around;">
                                <v-date-picker
                                        class="custom-date-picker"
                                        v-model="startDate"
                                        type="month"
                                        width="200"
                                        landscape
                                        color="#00a0df"
                                        locale="pt-pt"
                                        title="Data de Inicio"
                                        @change="getSoldProductsList()"
                                ></v-date-picker>
                                <v-date-picker
                                        class="custom-date-picker"
                                        v-model="endDate"
                                        type="month"
                                        width="200"
                                        landscape
                                        color="#00a0df"
                                        locale="pt-pt"
                                        @change="getSoldProductsList()"
                                ></v-date-picker>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <!-- ESTATISTICA -->
            <v-col cols="12" md="6">
                <v-card min-height="475">
                    <v-card-title class="card-title" style="background-color: #4caf50">
                        Estatistica
                    </v-card-title>
                    <v-card-text v-if="mostSoldClientProducts.length > 0">
                        <v-row >
                            <!-- MOST SALED PRODUCTS -->
                            <v-col cols="12">
                                <h4 class="pt-1" style="color: black">Produtos mais vendidos:</h4>
                                <v-simple-table dense>
                                    <thead>
                                    <tr>
                                        <th>Artigo</th>
                                        <th>Descricao</th>
                                        <th class="text-center">Quantidade</th>
                                        <th class="text-center">T.Liquido (€)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="product in mostSoldClientProducts">
                                        <td>{{product.Artigo}}</td>
                                        <td>{{product.Descricao}}</td>
                                        <td class="text-center">{{product.Quantidade}}</td>
                                        <td class="text-center">{{$money(parseFloat(product.totalLiquido))}}</td>
                                    </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-col>
                            <!-- MOST SALED BRANDS -->
                            <v-col cols="12" class="pt-0">
                                <h4 class="pt-1" style="color: black">Marcas mais vendidas:</h4>
                                <v-row>
                                    <v-col v-for="(brand, id) in mostSoldClientBrands" style="padding: 12px 6px">
                                        <div class="brand-div">
                                            <h2 style="display: flex; justify-content: center; align-items: center;min-height: 45px;">
                                                <span class="mr-1">{{id + 1}}.</span> <v-img contain max-height="30" max-width="100" :src="'https://static.bicimax.com/brands/'+brand.Marca+'.png'"/>
                                            </h2>
                                            <p class="brand-description mb-1">{{brand.Descricao}}</p>
                                            <p class="brand-info mb-0 pl-1">Quantidade: <strong>{{brand.Quantidade}}</strong></p>
                                            <p class="brand-info pl-1 pr-1">Valor: <strong>{{ $money(parseFloat(brand.totalLiquido)) }}</strong></p>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text v-else>
                        <h4 class="text-center pa-8">Sem estatistica para o cliente no intervalo de datas selecionado.</h4>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


        <!-- LIST OF PRODUCTS SOLD -->
        <v-row>
            <v-col>
                <v-data-table
                        :headers="headers"
                        :items="soldProductsList"
                        :items-per-page="25"
                        class="elevation-1 sold-products-table staff_table"
                        :loading="loading"
                        no-data-text="Sem compras efetuadas, no periodo selecionado."
                        :footer-props="{'items-per-page-options': [25,50,100,250,500,-1]}"
                        loading-text="Carregando artigos..."
                        dense
                >
                </v-data-table>
            </v-col>
        </v-row>


    </v-container>
</template>
<script>
import i18n from "@/plugins/i18n";
import Vue from "vue";

export default {
    data: () => ({
        loading: false,
        startDate: (new Date(new Date().getFullYear(), 0, 1)).toISOString().substr(0, 7),
        endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
        clients: [],
        soldProductsList: [],
        headers: [
            {text: 'Entitade', value: 'Entidade'},
            {text: 'Artigo', value: 'Artigo'},
            {text: 'Descrição', value: 'Descricao'},
            {text: 'Marca', value: 'Marca'},
            {text: 'Familia', value: 'Familia'},
            {text: 'Quantidade', value: 'Quantidade'},
            {text: 'Total Liquido', value: 'totalLiquido'},
        ],
        selectedClient: {},
        salesmanId: '',
        searchClient: '',
        mostSoldClientProducts: [],
        mostSoldClientBrands: [],
    }),
    created() {
        document.title = i18n.t('sales') + ' Cliente/Produtos'
        this.initialize()
    },
    methods: {
        initialize() {
            this.loading = true
            this.salesClient = []
            this.salesmanId = User.getters.getSalesmanId

            //Validar perfil de user do b2b
            if (!User.getters.writePermission('reports/sales/client/products')) {
                // this.salesmanId = User.getters.getSalesmanId
            }

            let request = {
                url: api.reportsSalesClientProducts(),
                data: {
                    salesman: this.salesmanId,
                    startDate: this.startDate,
                    endDate: this.endDate
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {

                            this.clients = responseData.clients
                            this.clients.splice(0, 0, {Cliente: 'ALL', Nome: 'Todos os clientes'});
                            // this.soldProductsList = responseData.soldProducts

                            if (this.clients.length > 0) {
                                //Pré-select first client
                                this.selectedClient = this.clients[0]
                                //Load sold products of first client
                                this.getSoldProductsList();
                            }

                            this.loading = false
                        } else {
                            this.loading = false
                        }
                    })
                    .catch(response => {
                        this.loading = false
                    })
        },
        getSoldProductsList() {
            this.loading = true
            this.salesmanId = User.getters.getSalesmanId

            //Validar perfil de user do b2b
            if (!User.getters.writePermission('reports/sales/client/products')) {
                // this.salesmanId = User.getters.getSalesmanId
            }

            let request = {
                url: api.reportsSalesClientProducts(),
                data: {
                    client: this.selectedClient.Nome === 'ALL' ? '' : this.selectedClient ,
                    salesman: this.salesmanId,
                    startDate: this.startDate,
                    endDate: this.endDate
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {

                            this.soldProductsList = responseData.soldProducts
                            this.mostSoldClientProducts = responseData.topProducts
                            this.mostSoldClientBrands = responseData.topBrands

                            this.loading = false

                            // setTimeout(this.verifyTableBackgroundColors, 100);
                        } else {
                            this.loading = false
                        }
                    })
                    .catch(response => {
                        this.loading = false
                    })
        },
        verifyTableBackgroundColors(){
            let rows = document.querySelectorAll(".sold-products-table table tbody tr"); // Ajusta conforme necessário
            let lastClient = null;
            let toggle = false;

            rows.forEach((row, index) => {
                const clientCell = row.cells[0]; // Assume que a 1ª coluna contém o código do cliente
                console.log(clientCell)

                if (clientCell) {
                    const clientId = clientCell.textContent.trim();

                    if (clientId !== lastClient) {
                        toggle = !toggle; // Alterna a cor sempre que o cliente muda
                        lastClient = clientId;
                    }

                    row.style.backgroundColor = toggle ? "#f0f0f0" : "#ffffff"; // Alterna entre duas cores
                }
            });
        }
    },
    computed: {}
}


</script>